import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { PermissionService } from '@app/core/auth/services/permission.service';
import { Observable, of, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-save-button',
  templateUrl: './save-button.component.html',
  styleUrls: ['./save-button.component.less']
})
export class SaveButtonComponent implements OnInit, OnChanges, OnDestroy {

  @Input() public isSaved: boolean;
  @Input() public isSaved$: Observable<boolean> = of(false);

  @Input() public shouldStick: boolean;
  @Input() public showTitle: boolean;
  @Input() public isBordered = false;
  @Input() public isEnrollment: boolean;
  @Input() public isWhiteTheme = false;
  @Input() public isTransparentTheme = false;

  @Input() public guid: string;
  @Input() public uncheckedTitle = 'Favorite Offer';
  @Input() public checkedTitle = 'Favorited';
  @Input() public groupTabIndex: number;

  @Output() public click = new EventEmitter<{ guid: string; isSaved: boolean }>();

  @HostBinding('attr.role') public role = 'presentation';

  public isGuestOrKnown = false;
  public title: string;

  private destroy$: Subject<void> = new Subject();

  // TODO: task 5537. remove 'offer:save' form appHasPermission check on all save buttons

  public constructor(
    private readonly permissionService: PermissionService,
    private cdr: ChangeDetectorRef
  ) { }

  public ngOnInit(): void {
    this.isGuestOrKnown = this.permissionService.hasDefined('known:access')
      || this.permissionService.hasDefined('guest:access');

    if (this.isEnrollment) {
      this.isSaved$
        .pipe(takeUntil(this.destroy$))
        .subscribe((isOfferSaved: boolean) => {
          this.isSaved = isOfferSaved;
          this.cdr.detectChanges();
        });
    }
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.isSaved && this.showTitle) {
      this.title = changes.isSaved.currentValue ? this.checkedTitle : this.uncheckedTitle;
    }
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public save($event: MouseEvent): void {
    // we need this hack with click on app-header element to force close search-bar details block
    if (document) {
      const el: HTMLElement = document.getElementsByTagName('app-header')[0] as HTMLElement;
      if (el) {
        el.click();
      }
    }

    $event.preventDefault();
    $event.stopPropagation();
    this.click.emit({
      guid: this.guid,
      isSaved: !this.isSaved,
    });
  }

  // Fixes Mozilla Firefox bug at https://jira.softserveinc.com/browse/BGP-441
  public addHoverClass(e: MouseEvent): void {
    (e.target as HTMLElement).classList.add('hover');
  }
  public removeHoverClass(e: MouseEvent): void {
    (e.target as HTMLElement).classList.remove('hover');
  }
}
