<header [class.header-no-auth]="!!openAccessTitle"
        data-qa="header">
  <div [class.mobile-listing-container]="!isDesktop && isFullScreenMap"
       [class.header-no-auth]="!!openAccessTitle"
       data-qa="header-container"
       class="header-primary">
    <div class="container main-container {{wideContainerClass}}"
         [class.desktop-header]="isDesktop && isFullScreenMap"
         [class.mobile-header]="!isDesktop && isFullScreenMap"
         [class.tablet-header]="isTablet && isFullScreenMap"
         [class.container-no-auth]="!!openAccessTitle"
         [@changeWidthAnimation]="animationState">
      <div class="left-header-side-wrapper"
           tabindex="-1">
        <div class="header-left"
             [appAutoTabIndex]="501"
             [enterKeyRoute]="['/']"
             role="link"
             aria-label="go to home page">
          <div class="skip-control-container">
            <div [id]="idRegistryService.get('reset-focus')"
                 class="reset-focus"
                 tabindex="500"></div>
            <a class="skip-control"
               tabindex="500"
               (keyup.enter)="setFocusOnElement('skipToContent', $event)">SKIP TO CONTENT</a>
            <a class="skip-control"
               tabindex="500"
               (keyup.enter)="setFocusOnElement('skipToSearch', $event)">SKIP TO SEARCH</a>
            <a class="skip-control"
               tabindex="500"
               (keyup.enter)="setFocusOnElement('skipToNavigation', $event)">SKIP TO NAVIGATION</a>
            <a *ngIf="currentUrl === '/offers'"
               class="skip-control skip-to-search-result"
               tabindex="500"
               (keyup.enter)="setFocusOnElement('skipToSearchResult', $event)">SKIP TO RESULTS</a>
          </div>
          <a [routerLink]="['/home']"
             class="logo">
            <app-logo (clickLogo)="clickLogo()"
                      route="/home"
                      [navigateRoute]="false"
                      logoContainerClass="header-logo"
                      role="none"></app-logo>
          </a>
        </div>
      </div>

      <app-search-bar *ngIf="isLoggedIn"
                      class="header-primary-search"
                      [ngClass]="{
                        'listing-search-bar': isDesktop && isFullScreenMap,
                        'hide-search-bar': !isDesktop && isFullScreenMap,
                        'compressed-header': isDesktop && isFullScreenMap,
                        'visibility-hidden': isMenuShown,
                        'profile-feature': isProfileFeature,
                        'multioption-header': isShoppingCartAvailable
                      }"
                      [groupTabIndex]="550"
                      (clickSearch)="clickSearch($event)">
      </app-search-bar>

      <ul class="profile"
          *ngIf="isLoggedIn"
          role="navigation">

        <li *ngIf="otherCountries.length && showMultiCountryOptions"
            class="menu-item country-selector">
          <a role="button"
             aria-haspopup="true"
             [appAutoTabIndex]="750"
             class="menu-link current-country"
             (click)="showSubMenu('countries')"
             (appClickOutside)="hideSubMenu('countries')"
             [attr.aria-expanded]="showSubMenus.countries">
            <img [src]="currentUserCountry.country_icon_url | imageUrl : { w: 18, h: 18 }"
                 class="submenu-icon"
                 loading="lazy"
                 [alt]="currentUserCountry.country_name_en">
            <img src="/assets/icons/ic-arrow-down.svg"
                 class="country-arrow-icon"
                 alt="change country"
                 role="presentation" />
          </a>

          <ul class="submenu"
              [class.submenu-expanded]="showSubMenus.countries"
              role="menu">
            <li>
              <a [appAutoTabIndex]="750"
                 role="link">
                <img [src]="currentUserCountry.country_icon_url | imageUrl : { w: 18, h: 18 }"
                     loading="lazy"
                     class="submenu-icon"
                     alt=""
                     role="presentation" />
                <span>{{currentUserCountry.country_name_en}}</span>
              </a>
            </li>

            <hr class="submenu-divider"
                role="separator">
            <p class="submenu-text-divider">Other Locations</p>

            <li *ngFor="let country of otherCountries; let i = index;">
              <a role="link"
                 [appAutoTabIndex]="750"
                 (click)="emitCountryChange(country)"
                 (focusout)="i === otherCountries.length - 1 ? hideSubMenu('countries'): ''">
                <img [src]="country.country_icon_url | imageUrl : { w: 18, h: 18 }"
                     loading="lazy"
                     class="submenu-icon"
                     alt=""
                     role="presentation" />
                <span>{{country.country_name_en}}</span>
              </a>
            </li>
          </ul>
        </li>

        <li *ngIf="otherCountries.length && showMultiCountryOptions"
            class="divider"
            role="separator"></li>

        <li *ngIf="this.currentUserCountry.country_code !== 'US'"
            class="menu-item">
          <a class="button menu-link currency-item__navbar"
             aria-haspopup="true"
             [appAutoTabIndex]="750"
             (click)="showSubMenu('currency')"
             (appClickOutside)="hideSubMenu('currency')"
             [attr.aria-expanded]="">USD
            <svg width="14"
                 height="14"
                 viewBox="0 0 14 14"
                 fill="none"
                 xmlns="http://www.w3.org/2000/svg"
                 class="currency-icon">
              <path class="theme-fill-secondary"
                    d="M6.33398 3.66659H7.66732V4.99992H6.33398V3.66659ZM6.33398 6.33325H7.66732V10.3333H6.33398V6.33325ZM7.00065 0.333252C3.32065 0.333252 0.333984 3.31992 0.333984 6.99992C0.333984 10.6799 3.32065 13.6666 7.00065 13.6666C10.6807 13.6666 13.6673 10.6799 13.6673 6.99992C13.6673 3.31992 10.6807 0.333252 7.00065 0.333252ZM7.00065 12.3333C4.06065 12.3333 1.66732 9.93992 1.66732 6.99992C1.66732 4.05992 4.06065 1.66659 7.00065 1.66659C9.94065 1.66659 12.334 4.05992 12.334 6.99992C12.334 9.93992 9.94065 12.3333 7.00065 12.3333Z" />
            </svg>
          </a>
          <ul class="submenu currency"
              role="menu"
              [class.submenu-expanded]="showSubMenus.currency"
              [class.submenu-expanded-currency]="showSubMenus.currency">
            <li *ngIf="!showCurrency"
                class="currency-item">
              <a class="currency-item__link"
                 role="link"
                 [appAutoTabIndex]="750">
                <p class="currency-item__link-content">
                  All prices referenced in this site and the partner sites to which we link
                  are USD unless
                  otherwise
                  noted.</p>
              </a>
          </ul>
        </li>


        <li class="divider"
            *ngIf="this.currentUserCountry.country_code !== 'US'"
            role="separator"></li>


        <li class="menu-item help">
          <span class="menu-link help-sub"
                (click)="showSubMenu('support')"
                (appClickOutside)="hideSubMenu('support')">Support</span>
          <ul class="submenu"
              role="menu"
              [class.submenu-expanded]="showSubMenus.support"
              [class.submenu-expanded-support]="showSubMenus.support">
            <li *ngFor="let supportOption of supportOptions; let i = index;"
                class="help-item">
              <a role="link"
                 [appAutoTabIndex]="750"
                 [href]="supportOption.href"
                 (focusout)="i === otherCountries.length - 1 ? hideSubMenu('support'): ''">
                <img class="help-item-icon"
                     loading="lazy"
                     [src]="supportOption.icon">
                <span class="help-item-name">{{supportOption.name}}</span>
              </a>
            </li>
          </ul>
        </li>

        <li *ngIf="isShoppingCartAvailable"
            class="divider"
            role="separator"></li>

        <li *ngIf="isShoppingCartAvailable"
            class="menu-item shopping-cart">
          <a role="link"
             class="menu-link"
             (click)="openCart()"
             [appAutoTabIndex]="700">
            <img src="/assets/header/shopping-cart.svg"
                 class="shopping-cart-icon"
                 alt="Shopping cart"
                 role="presentation" />
            <div *ngIf="!!productQuantity"
                 class="shopping-cart-quantity">{{productQuantity}}</div>
          </a>
        </li>

        <li *ngIf="!isGuestAutologin"
            appProfileFeatures
            class="divider"
            role="separator"></li>

        <li appProfileFeatures
            *ngIf="!isGuestOrKnownMode"
            class="menu-item name">
          <a [attr.aria-label]="userName + '\'s profile'"
             role="button"
             aria-haspopup="true"
             class="menu-link name-sub notranslate"
             (click)="showSubMenu('profile')"
             (appClickOutside)="hideSubMenu('profile')"
             [appAutoTabIndex]="760"
             [attr.aria-expanded]="showSubMenus.profile">
            <div class="user-name"
                 [ngClass]="{'hide-name': isNameHidden, 'sign-in': !avatarLetter}">{{userName}}</div>
            <app-avatar *ngIf="avatarLetter"
                        [letter]="avatarLetter"
                        role="presentation"
                        [class.avatar-only]="isNameHidden"></app-avatar>
          </a>
          <app-account-confirmation-dropdown *ngIf="!isTableOrMobile && (accountConfirmationOpen$ | async)"
                                             class="account-confirmation-dropdown"
                                             [avatarLetter]="avatarLetter"
                                             [userName]="userName"
                                             (appClickOutside)="onOutsideOfAccountConfirmationClick()">
          </app-account-confirmation-dropdown>

          <ul class="submenu submenu-profile"
              [class.submenu-expanded]="showSubMenus.profile"
              role="menu">

            <div>
              <li class="primary-item"><a role="link"
                   (click)="triggerAnimation('/my-account/dashboard')"
                   [appHasPermission]="'profile:view'"
                   [navigateTo]="['/my-account/dashboard']"
                   [appAutoTabIndex]="760">
                  <img src="/assets/icons/ic-dashboard.svg"
                       alt=""
                       role="presentation"
                       class="submenu-icon" />
                  My Account
                </a>
              </li>

              <li class="secondary-item">
                <a role="link"
                   (click)="triggerAnimation('/my-account/details')"
                   [appHasPermission]="'profile:view'"
                   [navigateTo]="['/my-account/details']"
                   [appAutoTabIndex]="760">
                  <img src="/assets/icons/ic-details.svg"
                       alt=""
                       role="presentation"
                       class="submenu-icon" />
                  Details
                </a>
              </li>

              <li class="secondary-item">
                <a role="link"
                   [appHasPermission]="'profile:view'"
                   [href]="orderHistoryUrl"
                   [appAutoTabIndex]="760">
                  <img src="/assets/icons/ic-order-history.svg"
                       alt="Order History"
                       role="presentation"
                       class="submenu-icon secondary-item__order-history" />
                  Order History
                </a>
              </li>

              <li class="secondary-item">
                <a role="link"
                   (click)="triggerAnimation('/my-account/security')"
                   [appHasPermission]="'profile:view'"
                   [navigateTo]="['/my-account/security']"
                   [appAutoTabIndex]="760">
                  <img src="/assets/icons/ic-security.svg"
                       alt=""
                       role="presentation"
                       class="submenu-icon" />
                  Password & Security
                </a>
              </li>

              <li class="secondary-item"
                  *ngIf="hasSubscriptionCenterAccess">
                <a role="link"
                   (click)="triggerAnimation('/my-account/email-subscriptions')"
                   [appHasPermission]="'profile:view'"
                   target="_blank"
                   [navigateTo]="['/my-account/email-subscriptions']"
                   [appAutoTabIndex]="760">
                  <img src="/assets/icons/ic-email.svg"
                       alt=""
                       role="presentation"
                       class="submenu-icon" />
                  Email Subscriptions
                </a>
              </li>

              <li class="secondary-item"
                  *ngIf="isLoyaltyEnabled">
                <a role="link"
                   [appHasPermission]="'profile:view'"
                   [href]="funlifeHistoryUrl"
                   [appAutoTabIndex]="760">
                  <img src="/assets/icons/ic_star.svg"
                       alt=""
                       role="presentation"
                       class="submenu-icon" />
                  FunLife Rewards
                </a>
              </li>

              <li class="secondary-item">
                <a role="link"
                   (click)="triggerAnimation('/my-account/favorites')"
                   [appHasPermission]="'profile:view'"
                   target="_blank"
                   [navigateTo]="['/my-account/favorites']"
                   [appAutoTabIndex]="760">
                  <img src="/assets/icons/ic_heart.svg"
                       alt=""
                       role="presentation"
                       class="submenu-icon" />
                  Favorites
                </a>
              </li>

              <li class="secondary-item">
                <a role="link"
                   (click)="triggerAnimation('/my-account/viewed')"
                   [appHasPermission]="'profile:view'"
                   target="_blank"
                   [navigateTo]="['/my-account/viewed']"
                   [appAutoTabIndex]="760">
                  <img src="/assets/icons/ic_viewed.svg"
                       alt=""
                       role="presentation"
                       class="submenu-icon" />
                  Viewed
                </a>
              </li>

              <li class="secondary-item"
                  *ngIf="features?.locations_map_new && currentUserCountry?.country_code === DEFAULT_COUNTRY">
                <a role="link"
                   (click)="triggerAnimation('/listing')"
                   [appHasPermission]="'profile:view'"
                   [navigateTo]="['/listing']"
                   target="_blank"
                   [appAutoTabIndex]="760">
                  <img src="/assets/icons/ic-near_me.svg"
                       alt=""
                       role="presentation"
                       class="submenu-icon" />
                  Near Me
                </a>
              </li>

            </div>

            <ng-container *ngIf="customLinksMyProfile && customLinksMyProfile.length > 0">
              <hr class="submenu-divider">
              <ng-container *ngFor="let customLink of customLinksMyProfile">
                <li *ngIf="customLink.location == 'My Account'">
                  <a role="link"
                     target="_blank"
                     href="{{ customLink.url }}"
                     (click)="onCustomLinkClick(customLink.url, customLink.guid)"
                     [appAutoTabIndex]="780">
                    {{ customLink.caption }}
                    <img src="/assets/icons/ic-new-window.svg"
                         alt="Open in new tab"
                         class="submenu-icon submenu-icon-right-position" />
                  </a>
                </li>
              </ng-container>
            </ng-container>

            <hr class="submenu-divider"
                role="presentation">

            <li class="primary-item"
                *ngIf="hasControlPanelAccess">
              <a role="link"
                 target="_blank"
                 [routerLink]="['/controlpanel']"
                 [appAutoTabIndex]="800">
                <img src="/assets/icons/ic-control-panel.svg"
                     alt=""
                     role="presentation"
                     class="submenu-icon" />
                Control Panel
              </a>
            </li>

            <li class="primary-item">
              <a *ngIf="employerPortalAccess?.has_access_to_ep || allowGlobalEPAccess"
                 role="link"
                 target="_blank"
                 [href]="employerPortalAccess?.ep_link"
                 [appAutoTabIndex]="800">
                <img src="/assets/icons/ic-employer-portal.svg"
                     alt="employer portal"
                     role="presentation"
                     class="submenu-icon" />
                Employer Portal
              </a>

            </li>

            <li class="primary-item">
              <a role="link"
                 (click)="triggerAnimation('/common/terms/of-use')"
                 [appHasPermission]="'profile:view'"
                 target="_blank"
                 [navigateTo]="['/common/terms/of-use']"
                 [appAutoTabIndex]="800">
                <img src="/assets/icons/ic-folder.svg"
                     alt=""
                     role="presentation"
                     class="submenu-icon" />
                Policies
              </a>
            </li>

            <li *ngIf="hasInlineEditAccess"
                class="primary-item admin-toggles">
              <a [appAutoTabIndex]="800"
                 (click)="adminFeaturesToggles('inline_admin'); $event.stopPropagation()">
                <img src="/assets/icons/icn-edit.svg"
                     alt=""
                     role="presentation"
                     class="submenu-icon" />
                Inline Admin
                <app-control-panel-toggle [isOn]="adminAccess.admin_edit_mode === 'inline_admin'">
                </app-control-panel-toggle>
              </a>
            </li>

            <li *ngIf="hasTakeoverAccess"
                class="primary-item admin-toggles">
              <a [appAutoTabIndex]="800"
                 (click)="adminFeaturesToggles('homepage_takeover', false, false, adminAccess.admin_edit_mode === 'homepage_takeover'); $event.stopPropagation()">
                <img src="/assets/icons/takeover-admin.svg"
                     alt=""
                     role="presentation"
                     class="submenu-icon" />
                Takeover Admin
                <app-control-panel-toggle [isOn]="adminAccess.admin_edit_mode === 'homepage_takeover'">
                </app-control-panel-toggle>
              </a>
            </li>

            <li class="primary-item">
              <a role="link"
                 [routerLink]="['/sign-out']"
                 [appAutoTabIndex]="850"
                 (focusout)="hideSubMenu('profile')">
                <img src="/assets/icons/ic-sign-out.svg"
                     alt=""
                     role="presentation"
                     class="submenu-icon" />
                Sign Out
              </a>
            </li>
          </ul>
        </li>
        <li *ngIf="isGuestOrKnownMode && !isGuestAutologin"
            class="menu-item name">
          <a class="menu-link help-sub"
             (click)="signUp($event)">{{ profileTitle }}</a>
          <app-account-confirmation-dropdown *ngIf="(accountConfirmationOpen$ | async) && !isTableOrMobile"
                                             class="account-confirmation-dropdown"
                                             [userName]="userName"
                                             (appClickOutside)=onOutsideOfAccountConfirmationClick()>
          </app-account-confirmation-dropdown>
        </li>
      </ul>

      <div *ngIf="!isLoggedIn && openAccessTitle">
        <a class="menu-link menu-link-no-access"
           data-qa="header-menu-link-no-access"
           (click)="signUp($event)">{{ openAccessTitle }}</a>
      </div>


      <div class="mobile-buttons-container"
           data-qa="header-mobile-buttons-container"
           *ngIf="!openAccessTitle">

        <div *ngIf="isShoppingCartAvailable"
             class="shopping-cart-container">
          <a role="link"
             class="menu-link"
             (click)="openCart()"
             [appAutoTabIndex]="700">
            <img src="/assets/header/shopping-cart.svg"
                 class="shopping-cart-icon"
                 alt="Shopping cart"
                 role="presentation" />
            <div *ngIf="!!productQuantity"
                 class="shopping-cart-quantity">{{productQuantity}}</div>
          </a>
          <div class="divider"
               role="separator"></div>
        </div>

        <a aria-haspopup="true"
           role="button"
           class="hamburger-container"
           aria-label="menu"
           [attr.aria-expanded]="isMenuShown"
           (click)="toggleMenu($event)">
          <div class="bar bar1"
               [class.close-menu-1]="isMenuShown"></div>
          <div class="bar bar2"
               [class.close-menu-2]="isMenuShown"></div>
          <div class="bar bar3"
               [class.close-menu-3]="isMenuShown"></div>
        </a>

      </div>


      <div class="hamburger-menu-container"
           role="menu"
           id="hamburger-menu-container"
           *ngIf="isMenuShown"
           [@toggleMenu]>
        <ul class="hamburger-menu container main-container"
            id="hamburger-menu"
            [@toggleMenuItems]="mobileMenu.length"
            *ngIf="isMenuShown">
          <li *ngFor="let item of mobileMenu"
              [class]="item.menuItemClass">
            <div *ngIf="!item.isProfileFeature && hasPermission(item.permissionRequired)">
              <ng-container *ngIf="item.expandable">
                <div class="menu-item-inner collapse-trigger"
                     [class.menu-item-expanded]="!item.isCollapsed"
                     [appHasPermission]="item.permissionRequired || null"
                     (click)="toggleCollapse(item)"
                     role="button"
                     aria-haspopup="true"
                     [attr.aria-label]="item.title"
                     [attr.aria-controls]="item.expandableId"
                     [attr.aria-expanded]="!item.isCollapsed">
                  <i [class]="item.iconClass"
                     [ngStyle]="item.iconStyle"
                     [class.menu-icon-expanded]="!item.isCollapsed"
                     role="presentation"></i>
                  <div class="menu-item-title"
                       [class.border-bottom-none]="!item.isCollapsed"
                       role="presentation">
                    <span role="presentation">{{ item.title }}</span>
                    <img role="presentation"
                         src="/assets/icons/ic-breadcrumb-arrow-right.svg"
                         class="collapse-icon"
                         [class.rotate]="!item.isCollapsed"
                         alt="">
                    <svg *ngIf="item.name === 'currency'"
                         width="17"
                         height="17"
                         viewBox="0 0 14 14"
                         fill="none"
                         xmlns="http://www.w3.org/2000/svg"
                         class="currency-icon">
                      <path class="theme-fill-secondary"
                            d="M6.33398 3.66659H7.66732V4.99992H6.33398V3.66659ZM6.33398 6.33325H7.66732V10.3333H6.33398V6.33325ZM7.00065 0.333252C3.32065 0.333252 0.333984 3.31992 0.333984 6.99992C0.333984 10.6799 3.32065 13.6666 7.00065 13.6666C10.6807 13.6666 13.6673 10.6799 13.6673 6.99992C13.6673 3.31992 10.6807 0.333252 7.00065 0.333252ZM7.00065 12.3333C4.06065 12.3333 1.66732 9.93992 1.66732 6.99992C1.66732 4.05992 4.06065 1.66659 7.00065 1.66659C9.94065 1.66659 12.334 4.05992 12.334 6.99992C12.334 9.93992 9.94065 12.3333 7.00065 12.3333Z" />
                    </svg>
                  </div>
                </div>
                <ul class="collapse-menu collapse"
                    [id]="item.expandableId"
                    [ngbCollapse]="item.isCollapsed">
                  <ng-container *ngFor="let subItem of item.collapseMenu">

                    <ng-container *ngIf="subItem.title !== 'LinksPlaceholder'">
                      <li class="collapse-item"
                          *ngIf="item.permissionRequired">
                        <div class="menu-item-inner"
                             [appHasPermission]="item.permissionRequired"
                             (permissionConfirmed)="navigateTo(subItem)">
                          {{ subItem.title }}
                        </div>
                      </li>
                      <li [class]="subItem.itemClass ? subItem.itemClass + ' collapse-item' : 'collapse-item'"
                          [ngStyle]="subItem.itemStyle"
                          *ngIf="!item.permissionRequired">
                        <div *ngIf="subItem.handler; else routerSubitemHandler"
                             class="menu-item-inner"
                             (click)="subItem.handler(item)">
                          {{ subItem.title }}
                        </div>

                        <ng-template #routerSubitemHandler>
                          <div class="menu-item-inner"
                               [class.currency-content]="item.name === 'currency'"
                               [routerLink]="subItem.link"
                               [queryParams]="subItem.queryParams">
                            {{ subItem.title }}
                          </div>
                        </ng-template>
                      </li>
                    </ng-container>

                  </ng-container>

                  <ng-container *ngIf="customLinksMyProfile.length > 0 && item.title == 'My Account'">
                    <ng-container *ngFor="let customLink of customLinksMyProfile; let i=index;">
                      <li class="collapse-item "
                          *ngIf="item.title === customLink.location"
                          [class.custom-link]="!i">
                        <div class="menu-item-inner">
                          <a target="_blank"
                             href="{{ customLink.url }}"
                             (click)="onCustomLinkClick(customLink.url, customLink.guid)">
                            <span> {{ customLink.caption }}</span>
                            <img src="/assets/icons/ic-new-window.svg"
                                 alt="Open in new tab"
                                 class="submenu-icon" />
                          </a>
                        </div>
                      </li>
                    </ng-container>
                  </ng-container>
                </ul>
              </ng-container>
              <ng-container *ngIf="!item.expandable">
                <div class="menu-item-inner"
                     *ngIf="!item.blockDisplay"
                     [appHasPermission]="item.permissionRequired || null"
                     (click)="item.isDynamicNav ? onDynamicNavClick(item) : onSecondaryHeaderClick(item, $event)"
                     role="link"
                     [attr.aria-label]="item.title">
                  <i class="menu-icon"
                     [class]="item.iconClass"
                     *ngIf="item.iconClass"
                     [ngStyle]="item.iconStyle"
                     role="presentation"></i>
                  <img class="nav-item-icon"
                       loading="lazy"
                       [src]="item.icon | imageUrl : { w: 26, h: 26 }"
                       alt=""
                       *ngIf="item.icon && !item.iconClass"
                       role="presentation">
                  <div class="menu-item-title"
                       loading="lazy"
                       [class.border-bottom-none]="item.noBorder"
                       role="presentation">
                    <span role="presentation"
                          *ngIf="!isGuestOrKnownMode || item.title !== 'Sign Out'">{{ item.title }}</span>
                    <span role="presentation"
                          *ngIf="isGuestOrKnownMode && item.title === 'Sign Out'"
                          (click)="signUp($event)">{{ profileTitle }}</span>
                  </div>
                </div>

                <ng-container *ngIf="customLinksMyProfile.length && item.title == 'My Account'">
                  <ng-container *ngFor="let customLink of customLinksMyProfile; let i=index;">
                    <ul class="collapse-menu">
                      <li class="collapse-item collapse-item--mobile-submenu"
                          [class.custom-link]="!i">
                        <div class="menu-item-inner menu-item-inner--mobile-submenu  menu-item-title--mobile-submenu-title">
                          <a target="_blank"
                             href="{{ customLink.url }}"
                             (click)="onCustomLinkClick(customLink.url, customLink.guid)">
                            <span> {{ customLink.caption }}</span>
                            <img src="/assets/icons/ic-new-window.svg"
                                 alt="Open in new tab"
                                 class="submenu-icon" />
                          </a>
                        </div>
                      </li>
                    </ul>
                  </ng-container>
                </ng-container>
              </ng-container>

            </div>

          </li>
        </ul>
      </div>
    </div>
  </div>
  <div *ngIf="isTableOrMobile"
       class="account-confirmation-dropdown-container">
    <app-account-confirmation-dropdown *ngIf="accountConfirmationOpen$ | async"
                                       class="account-confirmation-dropdown-mobile-table"
                                       [class.visibility-hidden]="isMenuShown"
                                       [userName]="userName"
                                       (appClickOutside)="onOutsideOfAccountConfirmationClick()">
    </app-account-confirmation-dropdown>
  </div>

  <div class="container main-container {{wideContainerClass}} header-secondary">
    <ul class="header-search-nav"
        role="navigation">
      <li *ngFor="let navOption of primaryNavItems"
          class="menu-item dynamic"
          [class.relative-to-link]="navOption.children ? getTotalColumnsCount(navOption.children) < 5 : false">
        <a role="button"
           aria-haspopup="true"
           class="menu-link"
           (click)="triggerNavLink(navOption, nav)"
           [ngbPopover]="navMenu"
           [animation]="false"
           placement="bottom"
           triggers="manual"
           popoverClass="nav-menu"
           #nav="ngbPopover"
           [class.is-expanded]="showSubMenus[navOption.id] && nav.isOpen()"
           (appClickOutside)="hideSubMenu(navOption.id)"
           [attr.aria-expanded]="showSubMenus[navOption.id]"
           [appAutoTabIndex]="501">
          <span class="icon-image"
                *ngIf="!!navOption.icon_color"
                role="presentation">
            <img class="nav-item-icon"
                 [src]="navOption.icon_color | imageUrl : { w: 26, h: 26 }"
                 loading="lazy"
                 role="presentation"
                 alt="">
          </span>
          {{ navOption.name }}
        </a>

        <ng-template #navMenu>
          <app-sub-navigation-menu *ngIf="navOption.children"
                                   [showSubMenu]="showSubMenus[navOption.id]"
                                   [primaryLevelItem]="navOption"
                                   [secondLevelNavItems]="navOption.children">
          </app-sub-navigation-menu>
        </ng-template>

      </li>
    </ul>
  </div>
</header>